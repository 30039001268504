import * as React from 'react'
import { Link } from 'gatsby'
import { FormattedMessage, useIntl } from 'react-intl'

const ProfessionalCta = ({props}) => {

  const { professionalCtaTitle, professionalCtaCopy } = props
  const { locale } = useIntl()
  const key = locale.toLowerCase()

  return (
    <div className="max-w-page py-16 md:pt-16 md:pb-8 text-center">
      <h2 className="headline-2 text-oceanic-blue mb-4 mx-auto w-full">{professionalCtaTitle}</h2>
      {
        locale === 'en-US' ? (
          <p className="body-2 lg:max-w-4xl mx-auto mb-10 md:mb-6">{professionalCtaCopy}</p>
        ) : (
          <p className="body-2 lg:max-w-4xl mx-auto mb-10 md:mb-6">
          {professionalCtaCopy.includes(",")
            ? professionalCtaCopy.split(",").map((sentence, index, array) => (
                <span key={index} className="inline lg:block">
                  {sentence.trim()}
                  {index < array.length - 1 && ", "}
                </span>
              ))
            : professionalCtaCopy}
          </p>
        )
      }

      {/* <p className="body-2 lg:max-w-4xl mx-auto mb-10 md:mb-6">{professionalCtaCopy}</p> */}
      <div className='relative z-0 flex flex-col justify-end flex-grow'>
        <div>
          <Link to={`/${key}/professionals`} className='button button-pink mb-0'>
            <div>
              <FormattedMessage id='professional-resources' />
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default ProfessionalCta
