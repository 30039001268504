import * as React from "react"
import Image from 'gatsby-image'
import { useLocation } from "@reach/router"

const Hero = ({ props }) => {

  const { hero, heroImage } = props
  const { pathname } = useLocation()
  const english = pathname.includes('/en-us/')

  const strongerStart = english ? 'a stronger start.' : 'un buen comienzo.'

  return (
    <div className="relative overflow-hidden lg:mt-30">
      <div className="max-w-page pt-18 flex flex-col lg:flex-row">
        <div className="flex-1">
          <div>
            <h1 className='headline-1 mb-4 text-magestic-purple max-w-2xl'>{hero.title}</h1>
            <p className="body-2 mb-4">
              {hero.copy}
              <a href={hero.link} target="_blank" rel="noopener noreferrer" className='underline'>{strongerStart}</a>
            </p>
          </div>
        </div>
        <div className="flex-1 relative">
          <Image
                fluid={heroImage.fluid}
                imgStyle={{objectFit: 'contain', objectPosition: 'center', width: 'initial', height: 'initial' }}
                className='hero-image z-10 w-full md:w-3/5 lg:w-full mx-auto mt-18 lg:mt-0'
                loading='eager'
                alt='Happy dad holding his son'
                aria-hidden
          />
        </div>
        <div className="fatherhood-hero-bg"></div>
      </div>
    </div>
  )
}

export default Hero