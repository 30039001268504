import React from 'react'
import PropTypes from 'prop-types'

const YouTube = ({ url }) => {
  let videoId = ''

  const urlSource = url || 'https://www.youtube.com/watch?v=LBimSTS0jII'
  const urlRegex = urlSource.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)

  if (urlRegex[2] !== undefined) {
    videoId = urlRegex[2].split(/[^0-9a-z_-]/i)
    videoId = videoId[0]
  } else {
    videoId = urlRegex
  }

  if (videoId !== null) {
    return (
      <div className='aspect-ratio-16/9'>
        <iframe
          wIdth='560'
          height='315'
          src={`https://www.youtube.com/embed/${videoId}?rel=0`}
          frameborder='0'
          allowFullScreen
          title='YouTube video'
        />
      </div>
    )
  } else {
    return null
  }
}

YouTube.defaultProps = {
  url: 'https://www.youtube.com/watch?v=LBimSTS0jII'
}

YouTube.propTypes = {
  url: PropTypes.string
}

export default YouTube
