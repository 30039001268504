import React from 'react'
import { graphql } from 'gatsby'

import Layout from '~components/layout'
import SEO from '~components/seo'
import Hero from '~components/fatherhood/hero'
import WhatKidsSee from '~components/fatherhood/what-kids-see'
import Validate from '~components/fatherhood/validate'
import Encourage from '~components/fatherhood/encourage'
import Motivate from '~components/fatherhood/motivate'
import EmotionalMuscles from '~components/fatherhood/emotional-muscles'
import CountyLookup from '~components/about/county-lookup'
import MoreResources from '~components/fatherhood/more-resources'
import Explore from '~components/fatherhood/explore'
import HearFromDads from '~components/fatherhood/hear-from-dads'
import ProfessionalCta from '~components/fatherhood/professional-cta'
import Sources from '~components/fatherhood/sources'

const Fatherhood = (props) => {
  const { section, locale } = props.pageContext
  const { location } = props
  const { settings } = props.data
  const countyDirectoryData = props.data.allContentfulCountyDirectory.nodes
  
  const fatherhood = props.data.contentfulFatherhood

  // County lookup component
  const aboutData = props.data.contentfulAbout
  let localLookup = {}

  if (aboutData.LocalLookup_Headline && aboutData.LocalLookup_Subline) {
    localLookup = {
      headline: aboutData.LocalLookup_Headline,
      subline: aboutData.LocalLookup_Subline,
      resultsHeadline: aboutData.LocalLookup_Results_Headline,
      resultsSubline: aboutData.LocalLookup_Results_Subline,
      resultsSummaryDefaultText: aboutData.LocalLookup_Results_CountySummaryDefaultText
    }
  }

  return (
    <Layout locale={locale} location={location} section={section}>
      <SEO lang={locale} title={fatherhood?.metaTitle || fatherhood.title} description={fatherhood?.metaDescription} />
      <Hero props={fatherhood} />
      <WhatKidsSee props={fatherhood} />
      <Validate props={fatherhood} />
      <Encourage props={fatherhood} />
      <EmotionalMuscles props={fatherhood} />
      <Motivate props={fatherhood} />
      <div className='wave-top' />
      <CountyLookup localLookup={localLookup} countyDirectoryData={countyDirectoryData} fatherhood={fatherhood} />
      <div className='wave-bottom' />
      <MoreResources props={fatherhood} />
      <Explore props={fatherhood} />
      <HearFromDads props={fatherhood} />
      <ProfessionalCta props={fatherhood} />
      <Sources props={fatherhood} sources={fatherhood.sources}/>
    </Layout>
  )
}

export const fatherhoodQuery = graphql`
  query fatherhoodQuery($locale: String!) {
    contentfulFatherhood(node_locale: { eq: $locale }) {
      title
      metaTitle
      metaDescription
      hero {
        title
        copy
        link
      }
      heroImage {
        fluid(maxWidth: 720, quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      whatKidsSeeTitle
      whatKidsSeeCopy
      whatKidsSeeImage {
        fluid(maxWidth: 240, quality: 100) { 
          ...GatsbyContentfulFluid
        }
      }
      whatKidsSeeYoutubeUrl
      validateTitle
      validateImage {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      validateImageMobile {
        fixed(width: 350, quality: 100) { 
          ...GatsbyContentfulFixed
        }
        file {
          url
        }
      }
      validateTestimonial1Text
      validateTestimonial1Name
      validateTestimonial2Text
      validateTestimonial2Name
      validateTestimonial3Text
      validateTestimonial3Name
      validatePrivacy
      encourageTitle
      encourageSubtitle
      encourageCopy
      encourageImage {
        fluid(maxWidth: 605, quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        fixed(width: 240, quality: 100) { 
          ...GatsbyContentfulFixed
        }
      }
      emotionalMusclesTitle
      emotionalMusclesCards {
        cards {
          title
          copy
        }
      }
      emotionalMusclesImages {
        file {
          url
        }
      }
      localFirst5Copy
      motivateTitle
      motivateImage {
        fluid(maxWidth: 240, quality: 100) { 
          ...GatsbyContentfulFluid
        }
      }
      motivateCopy {
        motivateCopy
      }
      moreResourcesTitle
      moreResourcesCopy
      moreResourcesImage {
        fluid(maxWidth: 240, quality: 100) { 
          ...GatsbyContentfulFluid
        }
      }
      moreResourcesLinks {
        resources {
          name
          url
        }
      }
      exploreTitle
      exploreArticles {
        contentful_id
      }
      hearFromDadsTitle
      hearFromDadsVideos {
        contentful_id
      }
      professionalCtaTitle
      professionalCtaCopy
      sourcesTitle
      sources {
        sources {
          name
          url
        }
      }
    }
    contentfulAbout(node_locale: { eq: $locale }) {
      LocalLookup_Headline
      LocalLookup_Subline
      LocalLookup_Results_Headline
      LocalLookup_Results_Subline
      LocalLookup_Results_CountySummaryDefaultText {
        json
      }
    }
    allContentfulCountyDirectory(filter: { node_locale: { eq: $locale } }, sort: { order: ASC, fields: county }) {
      nodes {
        id
        county
        phone
        slug
        title
        website
        zipCodes
        address {
          address
        }
        summary {
          json
        }
      }
    }
    settings: contentfulSettings(title: { eq: "Global Settings" }) {
      californiaStateSiteLink
    }
  }

`

export default Fatherhood