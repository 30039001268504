import * as React from 'react'
import Image from 'gatsby-image'

const Validate = ({props}) => {

  const {
    validateTitle,
    validateImage,
    validateImageMobile,
    validateTestimonial1Text,
    validateTestimonial1Name,
    validateTestimonial2Text,
    validateTestimonial2Name,
    validateTestimonial3Text,
    validateTestimonial3Name,
    validatePrivacy
  } = props

  return (
    <div className='bg-oceanic-blue'>
      <div className="max-w-page text-white">
        <h2 className="headline-2 text-center pt-12 md:pt-20 mb-10 md:mb-16">{validateTitle}</h2>
        <div className='relative'>
          <Image
              fluid={validateImage.fluid}
              imgStyle={{width: 'initial', height: 'initial' }}
              className='align-bottom hidden lg:block'
              loading='eager'
              alt='N/A'
              aria-hidden
          />
          <div className='testimonials lg:w-4/5 relative lg:absolute lg:top-32 lg:left-1/2 lg:transform lg:-translate-x-1/2'>
            <div className='testimonial-1 lg:w-2/4 lg:mx-auto mb-10 lg:mb-30'>
              <p className='headline-4 text-xl md:text-2xl mb-2 md:mb-4'>{validateTestimonial1Text}</p>
              <p className='body-3'>{validateTestimonial1Name}</p>
            </div>
            <div className='lg:flex justify-between'>
              <div className='testimonial-2 lg:w-2/5 mb-10 lg:mb-0'>
                <p className='headline-4 text-xl md:text-2xl mb-2 md:mb-4'>{validateTestimonial2Text}</p>
                <p className='body-3'>{validateTestimonial2Name}</p>
              </div>
              <div className='testimonial-3 lg:w-2/5 lg:text-right mb-10 lg:mb-0'>
                <p className='headline-4 text-xl md:text-2xl mb-2 md:mb-4'>{validateTestimonial3Text}</p>
                <p className='body-3'>{validateTestimonial3Name}</p>
              </div>
            </div>

          </div>
          <div className='lg:absolute lg:bottom-16 lg:right-24 text-right mb-10 lg:mb-0'>{validatePrivacy}</div>
          {/* <Image
              fixed={validateImageMobile.fixed}
              imgStyle={{width: 'initial', height: 'initial' }}
              className='lg:hidden'
              loading='eager'
              alt='N/A'
              aria-hidden
          /> */}
          <img 
            src={validateImageMobile.file.url} 
            alt="dad holding his daughter" 
            className="mx-auto lg:hidden"
          />
        </div>
      </div>
    </div>
  )
}

export default Validate