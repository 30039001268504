import * as React from 'react'
import Image from 'gatsby-image'
import YouTube from '~components/detail/youtube'
import { useIntl } from 'react-intl'

const WhatKidsSee = ({props}) => {

  const { locale } = useIntl()
  // console.log(locale)

  const { whatKidsSeeTitle, whatKidsSeeCopy, whatKidsSeeImage, whatKidsSeeYoutubeUrl } = props

  return (
    <div className="bg-soft-gray py-16">
      <div className="max-w-page text-center">
        <h2 className="headline-2 text-oceanic-blue mb-4">{whatKidsSeeTitle}</h2>
        <div className="mb-4 w-40 md:w-full mx-auto" style={{ maxWidth: '240px' }}>
          {/* <Image
            fixed={whatKidsSeeImage.fixed}
            imgStyle={{ height: 'auto' }} 
            loading='eager'
            alt='N/A'
            aria-hidden
          /> */}
          <Image
            fluid={whatKidsSeeImage.fluid}
            imgStyle={{ objectFit: 'contain', width: '100%', height: 'auto' }}
            loading='eager'
            alt='N/A'
            aria-hidden
          />
        </div>
        {
          locale === 'en-US' ? (
            <p className="body-2 md:w-4/5 lg:w-3/5 mx-auto mb-18 md:mb-10">
              {whatKidsSeeCopy}
            </p>
          ) : (
            <p className="body-2 md:w-4/5 mx-auto mb-18 md:mb-10">
              {whatKidsSeeCopy.includes(".")
                ? whatKidsSeeCopy.split(".").map((sentence, index, array) => (
                    <span key={index} className="inline lg:block">
                      {sentence.trim()}
                      {index < array.length - 1 && ". "}
                    </span>
                  ))
                : whatKidsSeeCopy}
            </p>
          )
        }

        {/* <p className={`body-2 md:w-4/5 lg:w-3/5 mx-auto mb-18 md:mb-10`}>{whatKidsSeeCopy}</p> */}
        <div className="lg:w-3/5 mx-auto">
          <YouTube url={whatKidsSeeYoutubeUrl} />
        </div>
      </div>
    </div>
  )
}

export default WhatKidsSee