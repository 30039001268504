import * as React from 'react'
import Image from 'gatsby-image'
import cx from 'classnames'

const EmotionalMuscles = ({props}) => {

  const { emotionalMusclesTitle, emotionalMusclesCards, emotionalMusclesImages} = props

  return (
    <>
      <div className="bg-eco-green text-white">
        <div className="max-w-page py-16 md:pt-22 md:pb-20">
          <h2 className="headline-2 text-center pb-10 md:pb-20 md:w-2/3 mx-auto">{emotionalMusclesTitle}</h2>
          <div className="cards flex flex-col space-y-8 lg:space-y-0 mx-auto md:w-2/3 lg:w-full lg:grid lg:grid-cols-6 lg:gap-x-20 lg:gap-y-18">
            {
              emotionalMusclesCards.cards.map((card, i) => (
                <div key={i} 
                  className={`card border-2 border-white rounded-3xl py-10 px-8 lg:col-span-2 ${i === 3 ? 'lg:col-start-2' : ''}`}
                >
                  <div className="icon">
                    <img 
                      className="mx-auto mb-6 lg:mb-18"
                      src={emotionalMusclesImages[i].file.url}
                    />
                  </div>
                  <h2 className={`headline-3 ${i == 1 ? 'mb-6 lg:mb-15' : 'mb-6'}`}>{card.title}</h2>
                  <p className="body-3">{card.copy}</p>
                </div>
              ))
            }
          </div>
        </div>
      </div>
      <div className="fatherhood-wave-bottom"></div>
    </>
  )
}

export default EmotionalMuscles