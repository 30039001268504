import * as React from "react"
import Image from 'gatsby-image'

const Motivate = ({props}) => {

  const { motivateTitle, motivateImage, motivateCopy } = props

  return (
    <div className="max-w-page pt-16 pb-8 text-center">
      <h2 className="headline-2 text-oceanic-blue">{motivateTitle}</h2>
      <div className="mt-8 md:mt-10 mb-6 w-40 md:w-full mx-auto" style={{ maxWidth: '240px' }}>
        <Image
          fluid={motivateImage.fluid}
          imgStyle={{ objectFit: 'contain', width: '100%', height: 'auto' }}
          loading='eager'
          alt='N/A'
          aria-hidden
        />
      </div>
      <p className="body-2 lg:max-w-4xl mx-auto">{motivateCopy.motivateCopy}</p>
    </div>
  )
}

export default Motivate