import * as React from "react"
import Image from 'gatsby-image'

const MoreResources = ({props}) => {

  const { moreResourcesTitle, moreResourcesCopy, moreResourcesImage, moreResourcesLinks } = props
  
  return (
    <>
      <div className="bg-oceanic-blue pt-25 -mt-25">
      </div>
      <div className="bg-oceanic-blue text-white pt-18 lg:pt-25 pb-16 lg:pb-32 -mt-px">
        <div className="max-w-page text-center">
          <h2 className="headline-2 mb-4 md:mb-5">{moreResourcesTitle}</h2>
          {/* <p className="body-2 w-full md:w-3/5 mx-auto mb-8">{moreResourcesCopy}</p> */}
          <p className="body-2 w-full md:w-3/5 mx-auto mb-8" dangerouslySetInnerHTML={{ __html: moreResourcesCopy }}></p>
          <div className="mb-12 md:mb-20 w-40 md:w-full mx-auto" style={{ maxWidth: '240px' }}>
            <Image
              fluid={moreResourcesImage.fluid}
              imgStyle={{ objectFit: 'contain', width: '100%', height: 'auto' }}
              loading='eager'
              alt='N/A'
              aria-hidden
            />
          </div>
          <div className="flex flex-col lg:flex-row flex-nowrap lg:gap-16 text-left">
            <div className="col w-full lg:w-1/2">
              {
                moreResourcesLinks.resources.slice(0,4).map((item, index) => (
                  <div key={index} className="border-b">
                    <a className="flex justify-between items-center py-4" href={item.url} target="_blank" rel="noopener noreferrer">
                      <span className="w-5/6 md:w-full">{item.name}</span>
                      <img src="/images/purple-arrow.png" />
                    </a>

                  </div>
                ))
              }
            </div>
            <div className="col w-full lg:w-1/2">
            {
                moreResourcesLinks.resources.slice(4).map((item, index) => (
                  <div key={index} className="border-b">
                    <a className="flex justify-between items-center py-4" href={item.url} target="_blank" rel="noopener noreferrer">
                      <span className="w-5/6 md:w-full">{item.name}</span>
                      <img src="/images/purple-arrow.png" className="w-7 h-7" />
                    </a>

                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
      <div className="blue-wave-bottom"></div>
    </>
  )
}

export default MoreResources